import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {excludeNilProps} from '@common/utils/exclude-nil-props';
import {HttpClientGetOptions} from '@generation-api-v2/util';
import {
  API_URL_PREFIX,
  ProjectCreateBodyDto,
  ProjectDto,
  ProjectPatchBodyDto,
  ProjectsGetParams,
} from '@projects-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProjectApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getAll(rawQueryParams?: ProjectsGetParams) {
    const requestOptions: HttpClientGetOptions = {};

    if (rawQueryParams !== undefined) {
      requestOptions.params = excludeNilProps(rawQueryParams);
    }

    return this.http.get<ProjectDto[]>(`${this.gatewayUrl}/${this.apiPrefix}/projects/`, requestOptions);
  }

  create(body: ProjectCreateBodyDto) {
    return this.http.post<string>(`${this.gatewayUrl}/${this.apiPrefix}/projects/`, body);
  }

  patch(body: ProjectPatchBodyDto, id: string) {
    return this.http.patch<ProjectDto>(`${this.gatewayUrl}/${this.apiPrefix}/projects/${id}`, body);
  }

  remove(id: string): Observable<void> {
    return this.http.delete<void>(`${this.gatewayUrl}/${this.apiPrefix}/projects/${id}`);
  }

  getById(projectId: string) {
    return this.http.get<ProjectDto>(`${this.gatewayUrl}/${this.apiPrefix}/projects/${projectId}`);
  }

  addImage(image: File): Observable<string> {
    const formData: FormData = new FormData();

    formData.append('image', image, image.name);

    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/images/project`, formData, {responseType: 'text'});
  }
}
