import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {excludeNilProps} from '@common/utils/exclude-nil-props';
import {HttpClientGetOptions} from '@generation-api-v2/util';
import {
  API_URL_PREFIX,
  PortfolioCreateBodyDto,
  PortfolioDto,
  PortfolioPatchBodyDto,
  PortfoliosGetParams,
} from '@projects-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PortfolioApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getAll(rawQueryParams?: PortfoliosGetParams): Observable<readonly PortfolioDto[]> {
    const requestOptions: HttpClientGetOptions = {};

    if (rawQueryParams !== undefined) {
      requestOptions.params = excludeNilProps(rawQueryParams);
    }

    return this.http.get<readonly PortfolioDto[]>(`${this.gatewayUrl}/${this.apiPrefix}/portfolios`, requestOptions);
  }

  getById(portfolioId: string): Observable<PortfolioDto> {
    return this.http.get<PortfolioDto>(`${this.gatewayUrl}/${this.apiPrefix}/portfolios/${portfolioId}`);
  }

  create(body: PortfolioCreateBodyDto): Observable<string> {
    return this.http.post<string>(`${this.gatewayUrl}/${this.apiPrefix}/portfolios`, body);
  }

  patch(body: PortfolioPatchBodyDto, portfolioId: string): Observable<PortfolioDto> {
    return this.http.patch<PortfolioDto>(`${this.gatewayUrl}/${this.apiPrefix}/portfolios/${portfolioId}`, body);
  }

  remove(id: string): Observable<null> {
    return this.http.delete<null>(`${this.gatewayUrl}/${this.apiPrefix}/portfolios/${id}`);
  }

  uploadImage(image: File): Observable<string> {
    const formData: FormData = new FormData();

    formData.append('image', image, image.name);

    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/images/portfolio`, formData, {responseType: 'text'});
  }
}
